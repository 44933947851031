<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          size="large"
          v-decorator="[
            'companyName',
            {
              rules: [
                { required: true, message: 'Please input Business name!' },
              ],
            },
          ]"
          placeholder="Business Name*"
        >
        </a-input>
      </a-form-item>
      <a-row>
          <a-col :span="12">
            <a-form-item>
              <a-input
                :disabled="whileSaving"
                v-decorator="['firstName', {
                  rules: [
                    { required: true, message: 'Please input Owner FirstName!' },
                  ],
                },]"
                size="large"
                placeholder="Owner First Name*"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="11" :offset="1">
            <a-form-item>
              <a-input
                :disabled="whileSaving"
                v-decorator="['lastName', {
                  rules: [
                    { required: true, message: 'Please input Owner LastName!' },
                  ],
                },]"
                size="large"
                placeholder="Owner Last Name*"
              >
              </a-input>
            </a-form-item>
          </a-col>
      </a-row>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          size="large"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input Business Admin Email!',
                },
              ],
            },
          ]"
          placeholder="Email*"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving || mode === 'edit'"
          size="large"
          v-decorator="[
            'username',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input Business Admin Username!',
                },
              ],
            },
          ]"
          placeholder="Username*"
        >
        </a-input>
      </a-form-item>
      <a-form-item v-if="mode==='add'">
        <a-input
          :disabled="whileSaving"
          type="password"
          size="large"
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input Business Admin Password!',
                },
              ],
            },
          ]"
          placeholder="Password*"
        >
        </a-input>
      </a-form-item>
      <a-form-item v-if="mode==='edit'">
        <a-input
          :disabled="whileSaving"
          type="password"
          size="large"
          v-decorator="[
            'password'
          ]"
          placeholder="New Password"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['contactNumber',{
              rules: [
                {
                  required: true,
                  message: 'Please input Business Admin Contact No!',
                },
              ],
            },
          ]"
          size="large"
          placeholder="Contact Number*"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['address']"
          size="large"
          placeholder="Street Address"
        >
        </a-input>
      </a-form-item>
      <a-row>
          <a-col :span="8">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      size="large"
                      v-decorator="['city']"
                      placeholder="City"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
          <a-col :span="7" :offset="1">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      v-decorator="['state']"
                      size="large"
                      placeholder="State"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
          <a-col :span="7" :offset="1">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      v-decorator="['zipcode']"
                      size="large"
                      placeholder="Zip Code"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
      </a-row>
      <a-form-item>
        <a-button
          :disabled="whileSaving"
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span v-if="whileSaving && mode==='add'" style="color: white">Saving...</span>
          <span v-if="!whileSaving && mode==='add'" style="color: white">Save Business</span>
          <span v-if="whileSaving && mode==='edit'" style="color: white">Updating...</span>
          <span v-if="!whileSaving && mode==='edit'" style="color: white">Update Business</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { BusinessApi } from "../../services";

export default {
  props: {
    mode: String,
    item: Object
  },
  data() {
    return {
      whileSaving: false,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
    this.form.resetFields();
  },
  mounted() {
    this.form.resetFields();
    if (this.mode === 'edit') {
      this.form.setFieldsValue({
        ...this.item
      });
      this.form.setFieldsValue({
        companyName: this.item.name
      })
    }
  },
  watch: {
    item() {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.form.setFieldsValue({
          companyName: this.item.name
        })
      }
    },
    mode(nv, ov) {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.form.setFieldsValue({
          companyName: this.item.name
        })
      }
    }
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.whileSaving = true;
            if (this.mode === 'add') {
              await BusinessApi.create(values);
            } else {
              values.businessId = this.item.businessId;
              values.systemId = this.item.systemId;
              await BusinessApi.update(values);
            }
            this.whileSaving = false;
            this.form.resetFields();
            if (this.mode === 'add') {
              this.$message.success(
                "Business and Business admin created successfully."
              );
            } else {
              this.$message.success(
                "Business and Business admin updated successfully."
              );
            }
            this.$emit('success', true);
          } catch (err) {
            this.whileSaving = false;
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$error.message.error(
                "Something went wrong. Please contact administrator."
              );
            }
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>
<style scoped>
a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>