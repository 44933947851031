























































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const BusinessModule = namespace("BusinessModule");
import { Business } from "../../store/modules/business/types";
import Icon from "../../components/Icon.vue";
import BusinessAdd from './BusinessAdd.vue';
import { sortStringAttributes } from '../../services';

@Component({
  components: {
    Icon,
    BusinessAdd
  }
})
export default class BusinessList extends Vue {
  @BusinessModule.Getter
  public businesses: Business[];
  public searchTerm = "";
  public activeMode = "add";
  public activeItem:any = {};

  public columns = [
    {
      title: "Name & Address",
      scopedSlots: { customRender: 'nameAndAddress' },
      slots: { title: "nameAndAddress" },
      sorter: (a, b) => sortStringAttributes(a.name, b.name),
    },
    {
      title: "BA Full Name",
      scopedSlots: { customRender: 'fullName' },
      slots: { title: "fullName" },
      sorter: (a, b) => sortStringAttributes(a.firstName, b.firstName),
    },
    {
      title: "BA Email",
      dataIndex: "email",
      sorter: (a, b) => sortStringAttributes(a.email, b.email),
    },
    {
      title: "BA Contact No",
      dataIndex: "contactNumber",
      sorter: (a, b) => a.contactNumber - b.contactNumber,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
      fixed: 'right',
      width: 150
    }
  ];
  visible = false;
  fetching = false;

  get modalTitle() {
    return this.activeMode === 'add' ? 'Add Business' : 'Edit Business';
  }
  
  @BusinessModule.Action
  public load!: () => Promise<Business[]>;

  @BusinessModule.Action
  public reset!: () => Promise<void>;

  @BusinessModule.Action
  public search!: (term:string) => Promise<Business[]>;


  async beforeDestroy() {
    this.reset();
  }
  
  public edit(item) {
    this.visible=true;
    this.activeMode='edit';
    this.activeItem=item;
  }

  async mounted() {
    this.fetching = true;
    try {
      await this.load();
    } catch(err) {
      console.log(err);
    }
    this.fetching = false;
  }

  navigate(to) {
    this.$router.push(to);
  }

  async businessAdded() {
    this.visible = false;
    try {
      await this.load();
    } catch(err) {
      console.log(err);
    }
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
    console.log("searched", term);
  }
}
